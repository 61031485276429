import styled from "styled-components"

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15rem;

  .container {
    width: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .moduleButton {
    border: 0;
    background-color: ${props => props.theme.palette.gray[300]};
    padding: 2rem;
    border-radius: ${props => `${props.theme.radius}rem`};
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    width: 15rem;
    transition: all 0.1s ease-in;
    cursor: pointer;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    svg {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
    }

    &:hover {
      background-color: ${props => props.theme.palette.secondary};
      color: ${props => props.theme.palette.secondaryContrast};
      box-shadow: ${props => props.theme.shadows[4]};
    }
  }
`
