import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;

  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    .dot {
      background: rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      height: 8px;
      margin: 0 4px;
      transform: scale(0.75);
      transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      width: 8px;

      &.active {
        background: ${(props) => props.theme.palette.primary};
        transform: scale(1);
        transition-delay: 0.4s;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
    position: relative;
    margin-bottom: 3rem;

    .screen {
      display: none;

      &.active {
        display: block;
      }

      h3 {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 2rem;
      }

      img {
        max-width: 80%;
      }
    }
  }

  /*}.body {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 35rem;

        .screens {
            position: relative;
            padding: 0;
            width: 100%;
            max-width: 40rem;

            .screen {
                opacity: 0;
                position: absolute;
                transform: translateX(-72px);
                transition: all 0.4s cubic-bezier(.25,.8,.25,1);
                list-style-type: none;
                text-align: center;
                width: 100%;
                font-size: 1.2rem;

                h3 {
                    font-weight: 700;
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                }

                &.active {
                    opacity: 1;
                    transform: translateX(0) scale(1);
                    transition-delay: 0.4s;

                    ~ .screen {
                        opacity: 0;
                        transform: translateX(72px);
                    }
                }

                .journey-img {
                    border-radius: 0.8rem;
                    margin-top: 2rem;
                    max-height: 24rem;
                }
            }
        }

        .prev-screen,
        .next-screen {
            align-self: stretch;
            background: none;
            border: 0;
            margin-top: 40%;
            cursor: pointer;
            opacity: 1;
            outline: none;
            padding: 16px;
            transform: scale(1);
            transition: transform 0.4s cubic-bezier(.25,.8,.25,1), color 0.4s cubic-bezier(.25,.8,.25,1), opacity 0.4s cubic-bezier(.25,.8,.25,1);
            z-index: 1000;
            position: absolute;

            &.disabled {
                opacity: 0.2;
            }
        }

        .prev-screen {
            float: left;
            left: 0;
        }

        .next-screen {
            float: right;
            right: 0;
        }
    }*/

  .footer {
    display: flex;
    justify-content: center;

    button {
      height: 5rem;
      font-size: 1.6rem;
      font-weight: 500;
      border: 0;
      background: ${(props) => props.theme.palette.secondary};
      text-transform: uppercase;
      letter-spacing: 0.15em;
      border-radius: 3rem;
      color: white;
      outline: 0;
      padding-top: 3px;
      transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      cursor: pointer;
      width: 100%;
      max-width: 30rem;
    }
  }
`;
