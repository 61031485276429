import React from "react"

import Container from "./styles"
import Icon from "components/Icons"

export default function({ history }) {
  const handleButtonClick = url => {
    history.push(`/${url}`)
  }

  return (
    <Container>
      <div className="container">
        <button className="moduleButton" onClick={() => handleButtonClick("home")}>
          <Icon icon="register" />
          Cadastro
        </button>
        <button className="moduleButton" onClick={() => handleButtonClick("dashboard")}>
          <Icon icon="dashboard" />
          Dashboard
        </button>
      </div>
    </Container>
  )
}
