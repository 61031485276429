import React, { useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf, faClock, faCheckCircle } from "@fortawesome/free-regular-svg-icons"

import Container, { ModalContainer } from "./styles"
import { get, post } from "api/api"
import Icons from "components/Icons"

export const PersonalData = ({ id, token, classname, icon }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [personalData, setPersonalData] = useState(null)
  const [certificates, setCertificates] = useState([])
  const [patrimony, setPatrimony] = useState({})
  const [pep, setPep] = useState({})
  const [ofac, setOfac] = useState({})
  let certificatesInterval = useRef()
  let patrimonyInterval = useRef()
  let pepInterval = useRef()
  let ofacInterval = useRef()

  const handleClick = e => {
    setModalOpen(true)
    fetchData()
  }

  const checkCertificates = data => {
    let allGreen = true

    for (const el of data) {
      if (el.color !== "green") allGreen = false
    }

    return allGreen
  }

  const checkPatrimony = data => {
    return data.valid && data.color === "green"
  }

  const checkPep = data => {
    return data.valid && data.color === "green"
  }

  const checkOfac = data => {
    return data.valid && data.color === "green"
  }

  const fetchCertificates = () => {
    certificatesInterval.current = setInterval(async () => {
      const res = await get({
        url: `/dash/personal_data/${id}/certificates`,
        token
      })

      if (res) {
        const { data } = res
        setCertificates(data)
        if (checkCertificates(data)) clearInterval(certificatesInterval.current)
      }
    }, 30000)
  }

  const fetchPatrimony = () => {
    patrimonyInterval.current = setInterval(async () => {
      const res = await get({
        url: `/dash/personal_data/${id}/patrimony`,
        token
      })

      if (res) {
        const { data } = res
        setPatrimony(data)
        if (checkPatrimony(data)) clearInterval(patrimonyInterval.current)
      }
    }, 30000)
  }

  const fetchPep = () => {
    pepInterval.current = setInterval(async () => {
      const res = await get({
        url: `/dash/personal_data/${id}/pep`,
        token
      })

      if (res) {
        const { data } = res
        setPep(data)
        if (checkPatrimony(data)) clearInterval(pepInterval.current)
      }
    }, 30000)
  }

  const fetchOfac = () => {
    ofacInterval.current = setInterval(async () => {
      const res = await get({
        url: `/dash/personal_data/${id}/ofac`,
        token
      })

      if (res) {
        const { data } = res
        setOfac(data)
        if (checkOfac(data)) clearInterval(ofacInterval.current)
      }
    }, 30000)
  }

  const fetchData = async () => {
    const res = await get({
      url: `/dash/personal_data/${id}`,
      token
    })

    if (res) {
      setPersonalData(res.data)

      const { certificates, patrimony, pep, ofac } = res.data

      setCertificates(certificates)
      setPatrimony(patrimony)
      setPep(pep)
      setOfac(ofac)

      if (!checkCertificates(certificates)) {
        fetchCertificates()
      }
      if (!checkPatrimony(patrimony)) fetchPatrimony()
      if (!checkPep(pep)) fetchPep()
      if (!checkOfac(ofac)) fetchOfac()
    }
  }

  const handleClose = () => {
    setModalOpen(false)
    clearInterval(certificatesInterval.current)
    clearInterval(patrimonyInterval.current)
    clearInterval(pepInterval.current)
  }

  const handleDownloadLink = async url => {
    if (url) {
      const res = await post({
        url: `/dash/download/certifcate`,
        token,
        data: {
          file_url: url
        }
      })

      if (res) {
        window.open(res.file_url)
      }
    }

    return false
  }

  return (
    <Container>
      <div onClick={handleClick} className="action-icon">
        <FontAwesomeIcon icon={icon} className={classname} />
      </div>
      {personalData && (
        <ModalContainer open={modalOpen} onClose={handleClose}>
          <div className="modal-card">
            <div className="header">
              <h2>Background Check</h2>
            </div>
            <div className="body">
              <div className="body-upper-grid">
                <div>
                  <div className="info-title">Dados Pessoais</div>
                  <div className="info-data">
                    <p>
                      <b>Nome:</b> <span>{personalData.name}</span>
                    </p>
                    <p>
                      <b>CPF:</b> <span>{personalData.cpf}</span>
                    </p>
                    <p>
                      <b>RG:</b> <span>{personalData.rg}</span>
                    </p>
                    <p>
                      <b>Nascimento:</b> <span>{personalData.birth_date}</span>
                    </p>
                    <p>
                      <b>Idade:</b> <span>{personalData.age}</span>
                    </p>
                    <p>
                      <b>Gênero:</b> <span>{personalData.gender}</span>
                    </p>
                    <p>
                      <b>Documento:</b> <span>{personalData.doc_type}</span>
                    </p>
                    <p>
                      <b>Telefone:</b> <span>{personalData.phone.value}</span>
                    </p>
                    <p>
                      <b>Email:</b> <span>{personalData.email.value}</span>
                    </p>
                    <p>
                      <b>Mãe:</b> <span>{personalData.mother_name}</span>
                    </p>
                  </div>
                </div>

                <div>
                  <div className="info-title">Endereço</div>
                  <div className="info-data">
                    <p>
                      <b>Endereço:</b> <span>{personalData.address.address}</span>
                    </p>
                    <p>
                      <b>Número:</b> <span>{personalData.address.number}</span>
                    </p>
                    <p>
                      <b>Complemento:</b> <span>{personalData.address.complement}</span>
                    </p>
                    <p>
                      <b>Bairro:</b> <span>{personalData.address.neighborhood}</span>
                    </p>
                    <p>
                      <b>Cidade:</b> <span>{personalData.address.city}</span>
                    </p>
                    <p>
                      <b>Estado:</b> <span>{personalData.address.uf}</span>
                    </p>
                    <p>
                      <b>CEP:</b> <span>{personalData.address.zipcode}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="body-info">
                <div className="info-container">
                  <span className="info-title">Certidões</span>
                  <div className="limited-size">
                    {certificates.length > 0 ? (
                      certificates.map((item, i) => (
                        <div key={i} className={`certificate ${item.color}`} onClick={() => handleDownloadLink(item.url)}>
                          <FontAwesomeIcon icon={item.color !== "yellow" ? faFilePdf : faClock} />
                          <p>{item.title}</p>
                        </div>
                      ))
                    ) : (
                      <div className="certificate-empty">Nenhuma certidão disponível</div>
                    )}
                  </div>
                </div>

                <div className="info-container">
                  <span className="info-title">PEP</span>
                  <React.Fragment>
                    {pep.color === "green" && (
                      <div className="register-ok">
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <p>Sem restrições</p>
                      </div>
                    )}
                    {pep.color === "yellow" && (
                      <div className="register-waiting">
                        <FontAwesomeIcon icon={faClock} />
                        Buscando informações
                      </div>
                    )}
                    {pep.color === "red" && (
                      <div className="limited-size">
                        {pep.results.length > 0 &&
                          pep.results.map((item, i) => (
                            <div key={i} className="pep">
                              <p>{item.office.function}</p>
                              De: <span>{item.office.start_date}</span>
                              Até: <span>{item.office.end_date}</span>
                            </div>
                          ))}
                      </div>
                    )}
                  </React.Fragment>
                </div>

                <div className="info-container">
                  <span className="info-title">Outras informações</span>
                  <div className="info-data patrimony">
                    <p>
                      <b>Patrimônio:</b>
                      {patrimony.color === "green" && <span>{patrimony.fx_patrimonio}</span>}
                      {patrimony.color === "yellow" && <span className="yellow">Buscando informações</span>}
                      {patrimony.color === "red" && <span className="patrimony-no-result">Nenhuma informação encontrada</span>}
                    </p>
                    <p>
                      <b>Renda:</b>
                      {patrimony.color === "green" && <span>{patrimony.fx_renda}</span>}
                      {patrimony.color === "yellow" && <span className="yellow">Buscando informações</span>}
                      {patrimony.color === "red" && <span className="patrimony-no-result">Nenhuma informação encontrada</span>}
                    </p>
                  </div>

                  <span className="info-title">OFAC</span>
                  <React.Fragment>
                    {ofac.color === "green" && (
                      <div className="register-ok">
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <p>Sem restrições</p>
                      </div>
                    )}
                    {ofac.color === "yellow" && (
                      <div className="register-waiting">
                        <FontAwesomeIcon icon={faClock} />
                        Buscando informações
                      </div>
                    )}
                    {ofac.color === "red" && (
                      <div className="register-error">
                        <Icons icon="alertCircle" />
                        Restrições encontradas
                      </div>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
            <div className="footer">
              <button onClick={handleClose}>Fechar</button>
            </div>
          </div>
        </ModalContainer>
      )}
    </Container>
  )
}
