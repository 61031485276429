import React, { useState, useEffect, useContext, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faBan, faEye, faThumbsUp, faThumbsDown, faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import CircularProgress from "@material-ui/core/CircularProgress"

import Root from "./styles"
import { get } from "api/api"
import { LoginStore } from "store"
import { Pagination } from "components/Pagination"
import { PersonalData } from "components/PersonalData"
import Results from "components/Results"

export default () => {
  const [{ token }] = useContext(LoginStore)
  const [list, setList] = useState([])
  const [pagination, setPagination] = useState({
    actual_page: 0,
    total_pages: null,
    total_registers: null
  })
  const [loaded, setLoaded] = useState(false)

  const fetchList = useCallback(
    async (page = pagination.actual_page) => {
      if (token) {
        setLoaded(false)
        const res = await get({
          url: `/dash/list?pg=${page}`,
          token: token
        })

        if (res) {
          setList(res.registers)
          setPagination({
            actual_page: res.actual_page,
            total_pages: res.total_pages,
            total_registers: res.total_registers
          })
          setLoaded(true)
        }
      }
    },
    [pagination.actual_page, token]
  )

  useEffect(() => {
    if (list.length === 0) fetchList()
  }, [fetchList, list.length])

  const handlePagination = async page => {
    fetchList(page)
  }

  const handleLink = async url => {
    if (url) {
      const res = await get({
        url,
        token
      })

      if (res) {
        window.open(res.url)
      }
    }

    return false
  }

  const Validated = () => <FontAwesomeIcon icon={faCheckCircle} className="cellIcon cellIconMarginRight validated" />
  const Attention = () => <FontAwesomeIcon icon={faMinusCircle} className="cellIcon cellIconMarginRight yellow" />
  const NotValidated = () => <FontAwesomeIcon icon={faBan} className="cellIcon cellIconMarginRight notValidated" />
  const ThumbsUpGreen = () => <FontAwesomeIcon icon={faThumbsUp} className="cellIcon validated" />
  const ThumbsUpYellow = () => <FontAwesomeIcon icon={faThumbsUp} className="cellIcon yellow" />
  const ThumbsDown = () => <FontAwesomeIcon icon={faThumbsDown} className="cellIcon notValidated" />

  return (
    <Root>
      {list.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Dados Pessoais</th>
              <th>Data/Hora</th>
              <th>Documento</th>
              <th>Liveness</th>
              <th>Face Match</th>
              <th>Quiz</th>
              <th>Background Check</th>
              <th>Resultado</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {list.map(item => (
              <tr key={item.id}>
                <td className={`border-${item.result} personal-data-cell`}>
                  <div>
                    {item.cpf.color === "green" && <Validated />}
                    {item.cpf.color === "yellow" && <Attention />}
                    {item.cpf.color === "red" && <NotValidated />}
                    {item.cpf.text}
                  </div>
                  <div>
                    {item.email.validated ? <Validated /> : <NotValidated />}
                    {item.email.text}
                  </div>
                  <div>
                    {item.phone.validated ? <Validated /> : <NotValidated />}
                    {item.phone.text}
                  </div>
                </td>
                <td align="center">{item.created_at}</td>
                <td>
                  <div className="alignCenter">
                    <div className={`cellIconContainer action-icon ${item.doc.front.link && "pointer"}`} onClick={() => handleLink(item.doc.front.link)}>
                      <FontAwesomeIcon icon={faEye} className={`cellIcon ${item.doc.back.is_ok ? "green" : "notValidated"} ${item.doc.front.link && "pointer"}`} />
                      <span>Frente</span>
                    </div>

                    <div className={`cellIconContainer action-icon ${item.doc.back.link && "pointer"}`} onClick={() => handleLink(item.doc.back.link)}>
                      <FontAwesomeIcon icon={faEye} className={`cellIcon ${item.doc.back.is_ok ? "green" : "notValidated"}`} />
                      <span>Verso</span>
                    </div>
                  </div>
                </td>
                <td>
                  {item.liveness && (
                    <div className="alignCenter">
                      <div className="cellIconContainer">
                        <div>{item.liveness.as ? <ThumbsUpGreen /> : <ThumbsDown />}</div>
                        <span>AS</span>
                      </div>

                      <div className="cellIconContainer">
                        <div>{item.liveness.mo ? <ThumbsUpGreen /> : <ThumbsDown />}</div>
                        <span>MO</span>
                      </div>
                    </div>
                  )}
                </td>

                <td>
                  <span className="alignCenter">
                    {item.facematch === "green" && <ThumbsUpGreen />}
                    {item.facematch === "yellow" && <ThumbsUpYellow />}
                    {item.facematch === "red" && <ThumbsDown />}
                  </span>
                </td>
                <td>{item.quiz !== null && <span className="alignCenter">{item.quiz ? <ThumbsUpGreen /> : <ThumbsDown />}</span>}</td>
                <td>{item.personal_data && <span className="alignCenter">{item.personal_data && <PersonalData id={item.id} token={token} icon={faEye} classname={`cellIcon ${item.background_check.color}`} />}</span>}</td>
                <td>
                  {item.result !== null && (
                    <span className="alignCenter">
                      <Results id={item.id} token={token} color={item.result} />
                    </span>
                  )}
                </td>
                <td />
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="12">
                <div className="paginationContainer">
                  <Pagination page={pagination.actual_page} totalItems={pagination.total_registers} totalPages={pagination.total_pages} onClick={handlePagination} totalPerPage={20} />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      )}

      {list.length === 0 && !loaded && (
        <div className="loaderContainer">
          <CircularProgress className="loader" />
        </div>
      )}
      {list.length === 0 && loaded && <div className="noResults">Nenhum registro encontrado</div>}
      {list.length > 0 && !loaded && (
        <div className="pagination-loading">
          <CircularProgress className="loader" />
        </div>
      )}
    </Root>
  )
}
