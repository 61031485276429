import React, { useContext, useCallback, useEffect, useState } from "react";
import * as QueryString from "query-string";

import Container from "./styles";
import { Store } from "store";
import { get } from "api/api";
import { SET_TOKEN, SET_ALTERNATE_TOKEN } from "store/reducers/tokenReducer";
import { SET_CALLBACK } from "store/reducers/callbackReducer";
import { verifyTokenRole } from "config/tokenRouter";
import journeymask from "assets/mask.jpg";
import journeylight from "assets/light.jpg";
import journeyhat from "assets/hat.jpg";
import { toast } from "react-toastify";
import { SET_STEPS } from "store/reducers/stepsReducer";

export default function ({ history, location }) {
  const [{ token }, dispatch] = useContext(Store);
  const [loaded, setLoaded] = useState(false);
  const [index, setIndex] = useState(0);
  const [livenessToken, setLivenessToken] = useState(null);

  const claimInitialToken = useCallback(async () => {
    const params = QueryString.parse(location.search);

    if (!token.value && params.token) {
      if (!params.callback) {
        return toast.error("Um callback é obrigatório!");
      } else {
        dispatch({ type: SET_CALLBACK, payload: params.callback });
      }
      dispatch({ type: SET_TOKEN, payload: params.token });
    } else {
      dispatch({ type: SET_ALTERNATE_TOKEN, payload: token.value });

      const res = await get({
        url: "/liveness",
        token: token.value,
        history,
      });

      if (res) {
        setLoaded(true);
        setLivenessToken(res.token);
        dispatch({ type: SET_STEPS, payload: res.additional_steps });
      }
    }
  }, [dispatch, history, location.search, token.value]);

  useEffect(() => {
    if (!loaded) claimInitialToken();
  }, [claimInitialToken, loaded]);

  const handleChangeIndex = (i) => {
    setIndex(i);
  };

  const handleNextIndex = () => {
    if (index === 2) return;
    setIndex(index + 1);
  };

  const handleInit = () => {
    dispatch({ type: SET_TOKEN, payload: livenessToken });
    return verifyTokenRole(livenessToken, history);
  };

  return (
    <Container>
      <div className="pagination">
        <span
          className={`dot ${index === 0 ? "active" : ""}`}
          onClick={() => handleChangeIndex(0)}
        />
        <span
          className={`dot ${index === 1 ? "active" : ""}`}
          onClick={() => handleChangeIndex(1)}
        />
        <span
          className={`dot ${index === 2 ? "active" : ""}`}
          onClick={() => handleChangeIndex(2)}
        />
      </div>
      <div className="body">
        <div className={`screen ${index === 0 ? "active" : ""}`}>
          <h3>Para garantir o melhor resultado</h3>
          <p>
            Levante a câmera até o nível dos seus olhos e mantenha seu rosto
            dentro da área marcada
          </p>
          <img src={journeymask} alt="" className="journey-img" />
        </div>

        <div className={`screen ${index === 1 ? "active" : ""}`}>
          <h3>Use uma boa iluminação</h3>
          <p>
            Escolha um local com boa iluminação e mantenha seu rosto totalmente
            visível. Evite pontos fortes de luz, como um lâmpada ou sol atrás de
            você.
          </p>
          <img src={journeylight} alt="" className="journey-img" />
        </div>

        <div className={`screen ${index === 2 ? "active" : ""}`}>
          <h3>Retire acessórios</h3>
          <p>Retire qualquer tipo de óculos e chapéus</p>
          <img src={journeyhat} alt="" className="journey-img" />
        </div>
      </div>
      {/*<div className="body">
        <ul className="screens">
          <li className={`screen ${index === 0 ? "active" : ""}`}>
            <h3>Para garantir o melhor resultado</h3>
            <p>
              Levante a câmera até o nível dos seus olhos e mantenha seu rosto
              dentro da área marcada
            </p>
            <img src={journeymask} alt="" className="journey-img" />
          </li>
          <li className={`screen ${index === 1 ? "active" : ""}`}>
            <h3>Use uma boa iluminação</h3>
            <p>
              Esolha um local com boa iluminação e mantenha seu rosto totalmente
              visível. Evite pontos fortes de luz, como um lâmpada ou sol atrás
              de você.
            </p>
            <img src={journeylight} alt="" className="journey-img" />
          </li>
          <li className={`screen ${index === 2 ? "active" : ""}`}>
            <h3>Retire acessórios</h3>
            <p>Retire qualquer tipo de óculos e chapéus</p>
            <img src={journeyhat} alt="" className="journey-img" />
          </li>
        </ul>
  </div>*/}
      <div className="footer">
        {index < 2 && (
          <button className="button next-screen" onClick={handleNextIndex}>
            Próximo
          </button>
        )}
        {index === 2 && (
          <button className="button finish" onClick={handleInit}>
            Iniciar
          </button>
        )}
      </div>
    </Container>
  );
}
