import styled from "styled-components"
import { Modal } from "@material-ui/core"

export default styled.div`
  .action-icon {
    cursor: pointer;
    padding: 2rem;
    border-radius: ${props => `${props.theme.radius}rem`};

    &:hover {
      background-color: ${props => props.theme.palette.gray[100]};
    }
  }
`

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-card {
    position: absolute;
    background-color: white;
    border-radius: ${props => `${props.theme.radius}rem`};
    padding: 2rem;
    outline: none;
    box-shadow: ${props => props.theme.shadows[4]};
    font-size: 1.4rem;

    .green {
      color: ${props => props.theme.palette.green};
    }

    .yellow {
      color: ${props => props.theme.palette.yellow};
    }

    .red {
      color: ${props => props.theme.palette.red};
    }

    .header {
      h2 {
        font-weight: 400;
        margin-bottom: 2rem;
        font-size: 2.4rem;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .body-upper-grid {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2rem;
      }

      .info-title {
        font-weight: 500;
        color: ${props => props.theme.palette.gray[500]};
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      .info-data {
        p {
          margin-bottom: 0.5rem;
        }

        b {
          display: inline-block;
          font-weight: 500;
          width: 10rem;
        }
      }

      .body-info {
        display: flex;

        .info-container {
          background-color: ${props => props.theme.palette.gray[100]};
          border-radius: ${props => `${props.theme.radius}rem`};
          padding: 2rem;

          &:not(:last-child) {
            margin-right: 2rem;
          }

          .info-title {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 1rem;
            display: block;
          }

          .info-data {
            font-size: 1.2rem;

            p {
              &:last-child {
                margin-bottom: 0;
              }

              b {
                display: inline-block;
                font-weight: 500;
                width: 6.5rem;
              }
            }
          }

          .certificate {
            display: flex;
            padding: 0.5rem 1rem;
            border-radius: ${props => `${props.theme.radius}rem`};
            align-items: center;

            &.green {
              color: ${props => props.theme.palette.green};
              cursor: pointer;
            }

            &.yellow {
              color: ${props => props.theme.palette.yellow};
            }

            &.red {
              color: ${props => props.theme.palette.red};
              cursor: pointer;
            }

            svg {
              margin-right: 0.5rem;
              width: 24px;
              height: 24px;
            }

            &:hover {
              background-color: ${props => props.theme.palette.gray[300]};
            }
          }

          .certificate-empty {
            font-weight: 500;
            color: ${props => props.theme.palette.gray[500]};
          }

          .limited-size {
            max-height: 300px;
            overflow: auto;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: ${props => props.theme.palette.primary};
        color: ${props => props.theme.palette.contrast};
        border: 0;
        border-radius: ${props => `${props.theme.radius}rem`};
        text-transform: uppercase;
        font-weight: 500;
        padding: 1rem 2rem;
        margin-top: 1rem;
        cursor: pointer;
        outline: none;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      margin-top: 1.5rem;
    }
  }

  .form-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 6.8rem;
    margin-top: 0.5rem;

    .title {
      width: 15rem;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .pep {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    p {
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    span {
      margin: 0 0.5rem;
      display: inline-block;
    }
  }

  .patrimony {
    margin-bottom: 2rem;
  }

  .patrimony-no-result {
    color: ${props => props.theme.palette.gray[500]};
  }

  .register-exists {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.red};

    svg {
      width: 22px;
      height: 22px;
      margin-right: 0.5rem;
    }
  }

  .register-ok {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.green};

    svg {
      width: 22px;
      height: 22px;
      margin-right: 0.5rem;
    }
  }

  .register-waiting {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.yellow};

    svg {
      width: 22px;
      height: 22px;
      margin-right: 0.5rem;
    }
  }

  .register-error {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.red};

    svg {
      width: 22px;
      height: 22px;
      margin-right: 0.5rem;
    }
  }
`
